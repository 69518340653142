import React from 'react'
import { useSelector } from 'react-redux'
import { Container, useScreenClass } from 'react-grid-system'
import Moment from 'react-moment'
import moment from 'moment'
import { Link, useParams } from 'react-router-dom'

import Box from 'styles/Box'
import { sameDay } from 'helpers/sameDay'
import { H3, H4 } from 'styles/Heading'
import { ArrowLeftIcon, ArrowRightIcon } from 'styles/Icon'
import Button from 'styles/Button'
import Alert from 'styles/Alert'
import Text from 'styles/Text'

import { QuoteDataType, ExtraType, ExcursionOptionType } from 'types'
import { ApplicationState } from 'reducers'
import OptionCard from 'components/OptionCard'

const Extras: React.FC = () => {
  const { itineraryId }: any = useParams()
  const { extras, itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)
  const screenClass = useScreenClass()
  const inlineButtons = ['sm', 'md', 'lg', 'xl', 'xxl'].includes(screenClass)

  if (!itinerary) {
    return null
  }

  return (
    <Container>
      {!extras.length && (
        <Box m={0} mt="2rem" mb="3rem">
          <Alert>
            Aan deze reis zijn geen extra&apos;s toegevoegd. Wilt u een extra boeken, laat het ons dan vooral weten!
          </Alert>
        </Box>
      )}

      {extras.length > 0 && (
        <>
          {itinerary?.status === 'open' && (
            <Box m={0} mt="4rem" mb="1rem">
              <p>Op zoek naar andere extras? Laat het ons weten dan passen we dit graag aan in de offerte.</p>
            </Box>
          )}

          {extras.map((extra: ExtraType, index: number) => {
            const days = moment(extra.endDate).diff(extra.startDate, 'days') + 1
            const optionIsInsurance = extra.options.filter(({ isInsurance }) => isInsurance).length > 0

            const startDayIndex =
              itinerary.days.findIndex(({ dayDate }) => {
                if (!extra.startDate) return false
                return sameDay(new Date(dayDate), new Date(extra.startDate))
              }) + 1

            let endDayIndex =
              itinerary.days.findIndex(({ dayDate }) => {
                if (!extra.endDate) return false
                return sameDay(new Date(dayDate), new Date(extra.endDate))
              }) + 1
            if (!endDayIndex) endDayIndex = itinerary.days.length
            const daysDifference = endDayIndex - (startDayIndex - 1)

            return (
              <React.Fragment key={index}>
                {!optionIsInsurance && (
                  <>
                    <H3>
                      {!itinerary.hideDates && (
                        <>
                          <Moment format="D MMMM">{extra.startDate}</Moment>
                          {days > 1 && (
                            <>
                              <span> - </span>
                              <Moment format="D MMMM YYYY">{extra.endDate}</Moment>
                              <span> </span>
                              <Text color="neutral" variant="light" inline>
                                ({days} dag{days !== 1 && 'en'})
                              </Text>
                            </>
                          )}
                        </>
                      )}

                      {itinerary.hideDates && (
                        <>
                          Dag {startDayIndex}
                          {daysDifference > 1 && (
                            <>
                              <span> - </span>
                              Dag {endDayIndex}
                              <span> </span>
                              <Text color="neutral" variant="light" inline>
                                ({daysDifference} dag{daysDifference !== 1 && 'en'})
                              </Text>
                            </>
                          )}
                        </>
                      )}
                    </H3>

                    <H4>{`${extra.country} - ${extra.location}`}</H4>
                  </>
                )}

                {extra.options.map((option: ExcursionOptionType) => {
                  return <OptionCard type="extra" {...option} key={option.id} optionId={extra.id} />
                })}
              </React.Fragment>
            )
          })}
        </>
      )}

      <Box m={0} mt="1rem" mb="8rem" inline={inlineButtons}>
        <Box m={0} mb="1rem" mr="1rem">
          <Link to={`/${itineraryId}/rentals`}>
            <Button type="neutral" variant="dark" small beforeIcon={<ArrowLeftIcon size="1rem" />}>
              Vervoer
            </Button>
          </Link>
        </Box>

        <Link to={`/${itineraryId}/summary`}>
          <Button type="primary" small afterIcon={<ArrowRightIcon size="1rem" />}>
            Samenvatting
          </Button>
        </Link>
      </Box>
    </Container>
  )
}

export default Extras
