import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import Logo from 'pages/Logo'
import Itinerary from 'pages/Itinerary'
import Accommodations from 'pages/Accommodations'
import Flights from 'pages/Flights'
import Excursions from 'pages/Excursions'
import Rentals from 'pages/Rentals'
import Summary from 'pages/Summary'
import Travellers from 'pages/Travellers'
import Book from 'pages/Book'
import BookingSuccess from 'pages/BookingSuccess'
import InvoicePDF from 'pages/InvoicePDF'
import QuotePDF from 'pages/QuotePDF'

import PageContainer from 'components/PageContainer'
import EmptyPageContainer from 'components/EmptyPageContainer'
import CheckoutPageContainer from 'components/CheckoutPageContainer'
import CheckoutExtras from 'pages/Checkout/CheckoutExtras'
import CheckoutTravellers from 'pages/Checkout/CheckoutTravellers'
import CheckoutPayment from 'pages/Checkout/CheckoutPayment'
import OutstandingPayment from 'pages/Outstanding/OutstandingPayment'
import SinglePageContainer from 'components/OutstandingPageContainer'
import Extras from 'pages/Extras/Extras'

const Routes: React.FC = () => (
  <>
    <Switch>
      <Route path="/" exact>
        <Logo />
      </Route>
      <Route
        exact
        path="/:itineraryId"
        render={({ match }) => <Redirect to={`/${match.params.itineraryId}/itinerary`} />}
      />
      <Route path="/:itineraryId/itinerary">
        <PageContainer isLandingPage>
          <Itinerary />
        </PageContainer>
      </Route>
      <Route path="/:itineraryId/flights">
        <PageContainer>
          <Flights />
        </PageContainer>
      </Route>
      <Route path="/:itineraryId/accommodations">
        <PageContainer>
          <Accommodations />
        </PageContainer>
      </Route>
      <Route path="/:itineraryId/excursions">
        <PageContainer>
          <Excursions />
        </PageContainer>
      </Route>
      <Route path="/:itineraryId/rentals">
        <PageContainer>
          <Rentals />
        </PageContainer>
      </Route>
      <Route path="/:itineraryId/extras">
        <PageContainer>
          <Extras />
        </PageContainer>
      </Route>
      <Route path="/:itineraryId/summary">
        <PageContainer>
          <Summary />
        </PageContainer>
      </Route>
      <Route path="/:itineraryId/travellers" exact>
        <PageContainer>
          <Travellers />
        </PageContainer>
      </Route>
      <Route path="/:itineraryId/book" exact>
        <PageContainer>
          <Book />
        </PageContainer>
      </Route>
      <Route path="/:itineraryId/checkout" exact>
        <CheckoutPageContainer>
          <CheckoutTravellers />
        </CheckoutPageContainer>
      </Route>
      <Route path="/:itineraryId/checkout/extras" exact>
        <CheckoutPageContainer>
          <CheckoutExtras />
        </CheckoutPageContainer>
      </Route>
      <Route path="/:itineraryId/checkout/payment" exact>
        <CheckoutPageContainer>
          <CheckoutPayment />
        </CheckoutPageContainer>
      </Route>
      <Route path="/:itineraryId/book/success" exact>
        <PageContainer>
          <BookingSuccess />
        </PageContainer>
      </Route>
      <Route path="/:itineraryId/quotes/:quoteId">
        <EmptyPageContainer>
          <QuotePDF />
        </EmptyPageContainer>
      </Route>
      <Route path="/:itineraryId/invoices/:invoiceId">
        <EmptyPageContainer>
          <InvoicePDF />
        </EmptyPageContainer>
      </Route>
      <Route path="/:itineraryId/outstanding">
        <SinglePageContainer>
          <OutstandingPayment />
        </SinglePageContainer>
      </Route>
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  </>
)

export default Routes
