import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

import { Container, useScreenClass } from 'react-grid-system'

import { QuoteDataType } from 'types'

import { ApplicationState } from 'reducers'
import { TravellerAccordion, TravellerAccordionComponent } from 'components/TravellerAccordion/TravellerAccordion'
import Box from 'styles/Box'
import Button from 'styles/Button'

import { ArrowLeftIcon, ArrowRightIcon } from 'styles/Icon'

const Travellers: React.FC = () => {
  const { itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)
  const { itineraryId }: any = useParams()
  const screenClass = useScreenClass()
  const inlineButtons = ['sm', 'md', 'lg', 'xl', 'xxl'].includes(screenClass)

  if (!itinerary) return null

  if (itinerary?.status !== 'booked' && !itinerary.isZRA) {
    window.location.href = `https://${window.location.host}/${itineraryId}/`
  }
  let travellerCount = []
  if (itinerary.onlyMainBooker) travellerCount = [0]
  else travellerCount = Array.from(Array(itinerary?.travellers ?? 0).keys())
  return (
    <Container>
      <Box mt="2rem">
        <TravellerAccordion
          allowMultipleExpanded
          allowZeroExpanded
          preExpanded={Array.from(Array(itinerary?.contacts.length ?? 0).keys())}
        >
          {travellerCount.map((index) => {
            return (
              <TravellerAccordionComponent
                disabled={!itinerary.adjustTravellers}
                opportunityId={itinerary.opportunityId}
                key={index}
                index={index}
                traveller={itinerary.contacts[index] ?? null}
                contacts={itinerary.contacts}
                travellerCount={itinerary.travellers}
              ></TravellerAccordionComponent>
            )
          })}
        </TravellerAccordion>
      </Box>

      <Box m={0} mt="1rem" mb="8rem" inline={inlineButtons}>
        <Box m={0} mb="1rem" mr="1rem">
          <Link to={itinerary?.status === 'booked' ? `/${itineraryId}/summary` : `/${itineraryId}/extras`}>
            <Button type="neutral" variant="dark" small beforeIcon={<ArrowLeftIcon size="1rem" />}>
              Samenvatting
            </Button>
          </Link>
        </Box>
        {(!itinerary?.isZRA && itinerary?.status === 'booked') || itinerary?.status !== 'booked' ? (
          <Link to={`/${itineraryId}/book`}>
            <Button type="primary" small afterIcon={<ArrowRightIcon size="1rem" />}>
              {itinerary?.status === 'booked' ? 'Factuur' : 'Reis boeken'}
            </Button>
          </Link>
        ) : (
          <></>
        )}
      </Box>
    </Container>
  )
}

export default Travellers
