/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useLocation, Redirect } from 'react-router-dom'
import useCompany from 'hooks/useCompany'
import { calculateWithFee } from 'helpers/calculateWithFee'

import getMenuItems, { MenuItem } from 'helpers/menuItems'

import { fetchQuote, fetchHighlightedExcursionsAndExtras, fetchIdealIssuers, createPaymentLink } from 'actions/Quote'

import Loader from 'pages/Loader'
import Error from 'pages/Error'

import Header from 'components/Header'
import Footer from 'components/Footer'
import ContactSection from 'components/ContactSection'

import { ItineraryType, QuoteStateType, QuoteDataType, FlightType } from 'types'
import { ApplicationState } from 'reducers'
import currency from 'helpers/currency'
import { Container, Row, Hidden, Col, Visible } from 'react-grid-system'
import Box from 'styles/Box'
import Text from 'styles/Text'
import moment from 'moment'
import SummaryCard from 'styles/SummaryCard'
import Moment from 'react-moment'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import styled from 'styled-components'
import { CheckoutTabs } from 'components/Checkout/CheckoutTabs'
import { CheckoutTabType } from 'components/Checkout/CheckoutTabs/CheckoutTabs'
import { CheckoutState } from 'reducers/Checkout'
import { CheckoutArrowButtons } from 'components/Checkout/CheckoutTabs/CheckoutArrowButtons'
import { createNotification } from 'actions/Notifications'
import { quickValidateContact } from 'helpers/validators'
import { CREDITCARD_MULTIPLIER, CREDITCARD_MULTIPLIER_AMEX } from 'constants/constants'

const SummaryAccordion = styled(Accordion)`
  border: none;
  width: 100%;
  .summary-text {
    font-family: 'Oswald', 'Raleway', sans-serif;
    padding: 0 15px;
    font-size: 18px;
    color: #000;
    text-transform: uppercase;
    font-size: 18px;
  }
  .accordion__button {
    background-color: #f2f2f2;
  }
  .accordion__button:after {
    transform: rotate(45deg);
  }

  .accordion__button[aria-expanded='true']::after,
  .accordion__button[aria-selected='true']::after {
    margin-top: 12px;
    transform: rotate(-135deg);
  }
`

type SummaryPropsType = {
  itinerary: ItineraryType
}
const Summary: React.FC<SummaryPropsType> = ({ itinerary }: SummaryPropsType) => {
  const {
    paymentInfo: { paymentMethod, paymentAmount },
  }: CheckoutState = useSelector((state: ApplicationState) => state.checkout)
  const days = moment(itinerary.endDate).diff(itinerary.startDate, 'days') + 1
  const isCreditcard = paymentMethod === 'creditcard'
  const isDeposit = paymentAmount === 'deposit'
  let price = isDeposit ? itinerary.deposit : itinerary.totalCosts
  const transactionCosts = isCreditcard ? price * CREDITCARD_MULTIPLIER - price : 0
  price += transactionCosts

  const totalCostsAmount = itinerary.optionBookingPaid ? itinerary.totalCosts - 50 : itinerary.totalCosts
  const depositAmount = itinerary.optionBookingPaid ? itinerary.deposit - 50 : itinerary.deposit
  return (
    <SummaryCard>
      <Row>
        <Col sm={6} xs={6}>
          <Box m={0} mb="0.5rem" mt="0.1rem">
            <Text size="sm" color="neutral" variant="darker">
              Naam:
            </Text>
          </Box>
        </Col>
        <Col sm={6} xs={6}>
          <Box m={0} mb="0.5rem">
            <Text size="md" color="neutral" variant="darker">
              {itinerary.name}
            </Text>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col sm={6} xs={6}>
          <Box m={0} mb="0.5rem" mt="0.1rem">
            <Text size="sm" color="neutral" variant="darker">
              Vertrek:
            </Text>
          </Box>
        </Col>
        <Col sm={6} xs={6}>
          <Box m={0} mb="0.5rem">
            <Text size="md" color="neutral" variant="darker">
              <Moment format="D MMMM YYYY">{itinerary.startDate}</Moment>
            </Text>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col sm={6} xs={6}>
          <Box m={0} mb="0.5rem" mt="0.1rem">
            <Text size="sm" color="neutral" variant="darker">
              Terugkomst:
            </Text>
          </Box>
        </Col>
        <Col sm={6} xs={6}>
          <Box m={0} mb="0.5rem">
            <Text size="md" color="neutral" variant="darker">
              <Moment format="D MMMM YYYY">{itinerary.endDate}</Moment>
            </Text>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col sm={6} xs={6}>
          <Box m={0} mb="0.5rem" mt="0.1rem">
            <Text size="sm" color="neutral" variant="darker">
              Reisduur:
            </Text>
          </Box>
        </Col>
        <Col sm={6} xs={6}>
          <Box m={0} mb="0.5rem">
            <Text size="md" color="neutral" variant="darker">
              {days} dag{days !== 1 && 'en'}
            </Text>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col sm={6} xs={6}>
          <Box m={0} mb="0.5rem" mt="0.1rem">
            <Text size="sm" color="neutral" variant="darker">
              Aantal personen:
            </Text>
          </Box>
        </Col>
        <Col sm={6} xs={6}>
          <Box m={0} mb="0.5rem">
            <Text size="md" color="neutral" variant="darker">
              {itinerary.travellers}
            </Text>
          </Box>
        </Col>
      </Row>
      <Box m={0} mt="2rem">
        <Row>
          <Col sm={6} xs={6}>
            <Box m={0} mb="0.5rem" mt="0.1rem">
              <Text size="sm" color="neutral" variant="darker">
                Prijs per persoon:
              </Text>
            </Box>
          </Col>
          <Col sm={6} xs={6}>
            <Box m={0} mb="0.5rem">
              <Text size="md" color="neutral" variant="darker">
                {currency.format(itinerary.totalCosts / (itinerary.travellers || 1))}
              </Text>
            </Box>
          </Col>
        </Row>

        <Row>
          <Col sm={6} xs={6}>
            <Box m={0} mb="0.5rem" mt="0.1rem">
              <Text size="sm" color="neutral" variant="darker">
                Totaalkosten:
              </Text>
            </Box>
          </Col>
          <Col sm={6} xs={6}>
            <Box m={0} mb="0.5rem">
              <Text size="md" color="neutral" variant="darker">
                {currency.format(itinerary.totalCosts)}
              </Text>
            </Box>
          </Col>
        </Row>

        {itinerary.optionBookingPaid ? (
          <Row>
            <Col sm={6} xs={6}>
              <Box m={0} mb="0.5rem" mt="1rem">
                <Text size="sm" color="neutral" variant="darker">
                  Totaal voldaan:
                </Text>
              </Box>
            </Col>
            <Col sm={6} xs={6}>
              <Box m={0} mb="0.5rem" mt="1rem">
                <Text size="md" color="neutral" variant="darker">
                  {currency.format(50.0)}
                </Text>
              </Box>
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </Box>
      {/* {transactionCosts > 0 && (
        <Box m={0} mt="2rem">
          <Row>
            <Col sm={6} xs={6}>
              <Box m={0} mb="0.5rem" mt="0.1rem">
                <Text size="sm" color="neutral" variant="darker">
                  Transactiekosten Mollie:
                </Text>
              </Box>
            </Col>
            <Col sm={6} xs={6}>
              <Box m={0} mb="0.5rem">
                <Text size="md" color="neutral" variant="darker">
                  {currency.format(transactionCosts)}
                </Text>
              </Box>
            </Col>
          </Row>
        </Box>
      )} */}
      {/* <Box m={0} mt="2rem" p={0}>
        {itinerary.deposit > 0 && itinerary.deposit !== itinerary.totalCosts && (
          <Row>
            <Col sm={6} xs={6}>
              <Box m={0} mb="0.5rem" mt="0.4rem">
                <Text size="sm" color="neutral" variant="darker">
                  Aanbetaling:
                </Text>
              </Box>
            </Col>
            <Col sm={6} xs={6}>
              <Box m={0} mb="0.5rem">
                <Text size="lg" color="neutral" variant="darker" fontWeight="bold">
                  {currency.format(price)}
                </Text>
              </Box>
            </Col>
          </Row>
        )}
      </Box> */}
    </SummaryCard>
  )
}

const Subtitle: React.FC<{ status: string }> = ({ status }: { status: string }) => {
  const { data }: QuoteStateType = useSelector((state: ApplicationState) => state.quote)
  return (
    <Box m={0} mb="2rem">
      {status === 'open' && (
        <p>
          U staat op het punt om deze {data.itinerary?.isExcursion ? "extra's" : 'reis'} te boeken. Heeft u nog vragen
          over deze offerte, neem dan gerust contact met ons op.
        </p>
      )}
    </Box>
  )
}

const CheckoutPageContainer = ({ children, isLandingPage }: any) => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { itineraryId }: any = useParams()
  const { company, short } = useCompany()
  const { data, isFetching, fetchingError }: QuoteStateType = useSelector((state: ApplicationState) => state.quote)
  const { travellersEdited, travellersValid, paymentInfo }: CheckoutState = useSelector(
    (state: ApplicationState) => state.checkout
  )
  const { highlightedExcursions, highlightedExtras }: QuoteDataType = useSelector(
    (state: ApplicationState) => state.quote.data
  )
  const { flights }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)

  useEffect(() => {
    async function handleFetchQuote() {
      await dispatch(fetchQuote(itineraryId, company))
      await dispatch(fetchHighlightedExcursionsAndExtras(itineraryId, company))
      await dispatch(fetchIdealIssuers(short))
    }
    handleFetchQuote()
  }, [dispatch, itineraryId, company, short])

  if (
    data.itinerary?.contacts.some((contact) => {
      return !quickValidateContact(contact)
    }) &&
    !window.location.pathname.endsWith('/checkout')
  ) {
    return <Redirect to={`/${itineraryId}/checkout`} />
  }

  if (isFetching) {
    return <Loader />
  }

  if (fetchingError) {
    return <Error />
  }

  const { itinerary } = data

  if (!itinerary) {
    return null
  }

  if (itinerary.isZRA) {
    return <Redirect to={`/${itineraryId}/book`}></Redirect>
  }

  if (itinerary.status !== 'open') {
    return <Redirect to={`/${itineraryId}/book/success`}></Redirect>
  }

  const selectedFlight = flights?.find((flight: FlightType) => flight.options.find((option) => option.selected))
  const selectedFlightOption = selectedFlight?.options.find((option) => option.selected)

  const menuItems = getMenuItems(false, false, false, false, false, false)
  const index: number = menuItems.findIndex((item: MenuItem) => pathname.includes(item.link))
  const name = isLandingPage ? itinerary?.name : menuItems[index]?.name

  const amountToPay =
    paymentInfo.paymentAmount === 'deposit' && !!itinerary.deposit ? itinerary.deposit : itinerary.totalCosts
  const amountWithMultiplier =
    paymentInfo.paymentMethod === 'creditcard'
      ? paymentInfo.creditcardIssuer === 'amex'
        ? amountToPay * CREDITCARD_MULTIPLIER_AMEX
        : amountToPay * CREDITCARD_MULTIPLIER
      : amountToPay

  const disablePayment =
    !paymentInfo.paymentMethod ||
    !paymentInfo.paymentAmount ||
    data.paymentMethods?.length === 0 ||
    (paymentInfo.termsConditions !== '' && paymentInfo.termsConditions?.length === 0) ||
    paymentInfo.termsConditions === '' ||
    (paymentInfo.paymentMethod === 'creditcard' && amountWithMultiplier > (paymentInfo?.creditcardMaximum ?? 0)) ||
    amountToPay <= 0

  const tabs: CheckoutTabType[] = [
    {
      name: `Extra's`,
      path: `/${itineraryId}/checkout/extras`,
      hidden: highlightedExcursions.length === 0 && highlightedExtras.length === 0 && selectedFlightOption?.holdLuggage,
      disabledNextTab: () => {
        return amountToPay <= 0
      },
    },
    {
      name: 'Betaling',
      path: `/${itineraryId}/checkout/payment`,
      disabledNextTab: () => {
        return !!disablePayment
      },
    },
    {
      name: 'Betalen',
      onClick: async () => {
        if (!itinerary) return
        const isDeposit = paymentInfo.paymentAmount === 'deposit'
        const isOption = paymentInfo.paymentAmount === 'option'

        // const amountValue = isDeposit ? itinerary?.deposit : isOption ? 50.0 : itinerary?.totalCosts

        const amountValue = isOption
          ? 50.0
          : isDeposit
          ? itinerary.optionBookingPaid
            ? itinerary.deposit - 50
            : itinerary.deposit
          : itinerary.optionBookingPaid
          ? itinerary.totalCosts - 50
          : itinerary.totalCosts

        const amountWithFee = calculateWithFee(paymentInfo, amountValue)

        const fee =
          paymentInfo.paymentMethod === 'creditcard' ? (Number(amountWithFee) - Number(amountValue)).toFixed(2) : '0.00'

        const payment = {
          short,
          request: {
            amount: {
              currency: 'EUR',
              value: amountWithFee,
            },
            description: `Betaling ${company} ${itinerary.relationNumber}`,
            redirectUrl: `https://${window.location.host}/${itineraryId}/book/success`,
            webhookUrl: `${process.env.REACT_APP_API_URL}/payment/hook/${short}`,
            metadata: {
              itineraryId,
              short,
              isDeposit,
              isOption,
              fee,
            },
            method: paymentInfo.paymentMethod?.toLowerCase().replace(/\s/g, ''),
            issuer: paymentInfo.idealIssuer,
          },
          isTest: process.env.NODE_ENV !== 'production',
          travellers: itinerary.contacts,
          opportunityId: itinerary?.opportunityId,
        }
        try {
          const action = await dispatch(createPaymentLink(payment))
          // eslint-disable-next-line no-underscore-dangle
          window.location.href = action.payload.data._links.checkout.href
        } catch (e) {
          dispatch(
            createNotification({
              content:
                'De betaling kon niet verwerkt worden. Neem contact op met onze klantenservice om u verder te helpen.',
              status: 'danger',
            })
          )
        }
      },
      hidden: true,
      disabledNextTab: () => {
        return !!disablePayment
      },
    },
  ]

  if (!itinerary?.isExcursion)
    tabs.unshift(
      {
        name: 'Boeking',
        path: `/${itineraryId}/book`,
        hidden: true,
      },
      {
        name: 'Reizigers',
        path: `/${itineraryId}/checkout`,
        disabledNextTab: () => {
          const anyEdited: boolean = travellersEdited.some((edited: boolean) => edited)
          const anyInvalid: boolean = travellersValid.some((valid: boolean) => !valid)
          return anyEdited || anyInvalid || !itinerary.contacts.length
        },
      }
    )

  return (
    <>
      <Header name={name} small={!isLandingPage} isCheckout />
      <Container>
        <Box mt="2rem">
          <Row>
            <Hidden xs sm md lg>
              <Col xs={12}>
                <Subtitle status={itinerary.status} />
              </Col>
            </Hidden>
            <Col sm={12} xl={8}>
              <Visible xs sm md lg>
                <Row>
                  <CheckoutTabs tabs={tabs} />
                </Row>
              </Visible>
              <Visible xs sm md lg>
                <Subtitle status={itinerary.status} />
                <SummaryAccordion allowZeroExpanded>
                  <AccordionItem uuid="summary">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span className="summary-text">Samenvatting</span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Summary itinerary={itinerary}></Summary>
                    </AccordionItemPanel>
                  </AccordionItem>
                </SummaryAccordion>
              </Visible>
              <Hidden xs sm md lg>
                <Row>
                  <CheckoutTabs tabs={tabs} />
                </Row>
              </Hidden>
              <Row style={{ ...(!itinerary.isExcursion ? { minHeight: '500px' } : {}), marginTop: '2rem' }}>
                <Col>{children}</Col>
              </Row>
              <Row>
                <Col>
                  <CheckoutArrowButtons tabs={tabs} />
                </Col>
              </Row>
            </Col>
            <Hidden xs sm md lg>
              <Col sm={12} md={12} xl={4}>
                <Summary itinerary={itinerary} />
              </Col>
            </Hidden>
          </Row>
        </Box>
      </Container>
      <Footer />
      <ContactSection />
    </>
  )
}

export default CheckoutPageContainer
