import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'
import Moment from 'react-moment'
import moment from 'moment'
import { Link, useParams } from 'react-router-dom'

import currency from 'helpers/currency'

import { Row, Col, Container, useScreenClass } from 'react-grid-system'

import Box from 'styles/Box'
import { H3 } from 'styles/Heading'
import Text from 'styles/Text'
import { CheckIconRounded, ArrowLeftIcon, ArrowRightIcon, RemoveIconRounded, AddIcon } from 'styles/Icon'
import Alignment from 'styles/Alignment'
import Card from 'styles/Card'
import Button from 'styles/Button'
import Label from 'styles/Label'
import TransparentButton from 'styles/TransparentButton'

import { ExcursionOptionType, ItineraryType, QuoteDataType, SummaryOptionType } from 'types'

import { ApplicationState } from 'reducers'
import { fetchQuote, selectExcursionOrExtra } from 'actions/Quote'
import useCompany from 'hooks/useCompany'
import { createNotification } from 'actions/Notifications'
import LabelButton from 'styles/LabelButton'

const ListCard = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
`

const SummaryIconWrapper = styled.div`
  width: 2rem;
`

const getDescription = (option: SummaryOptionType, itinerary: ItineraryType) => {
  if (option.type === 'Flight') {
    return (
      <span>
        {option.units} ticket{option.units === 1 ? '' : 's'} {option.description}
      </span>
    )
  }

  if (option.type === 'Accommodation') {
    const nightText = `nacht${option.duration !== 1 ? 'en' : ''}`
    return (
      <span>
        {option.duration} {nightText} verblijf in {option.description}
      </span>
    )
  }

  if (option.type === 'Excursion' || option.type === 'Extra') {
    if (option.included) {
      return (
        <span>
          {option.description}{' '}
          {option?.units && option?.adjustParticipants
            ? `voor ${option.units} ${option.units === 1 ? 'persoon' : 'personen'}`
            : ''}
        </span>
      )
    }

    return (
      <span>
        <span>
          {option.description}{' '}
          {option?.units && option?.adjustParticipants
            ? `(${option.units} ${option.units === 1 ? 'persoon' : 'personen'})`
            : ''}
        </span>
        {(!itinerary?.hidePrices || (!!itinerary?.hidePrices && !!itinerary?.showUpsell)) && (
          <Box m={0} mt="0.3rem">
            <Text color="neutral" size="sm">
              Meerprijs per persoon: {currency.format(option.sellAmount / (option.units || 1))}
            </Text>
          </Box>
        )}
      </span>
    )
  }

  if (option.type === 'Rental') {
    const dayText = `dag${option.duration !== 1 ? 'en' : ''}`
    return (
      <span>
        {option.duration} {dayText} beschikking over een {option.description}
      </span>
    )
  }

  return <span>{option.description}</span>
}

const SummaryItems = ({ type, items, itinerary, isSubmitting, setIsSubmitting }: any) => {
  const dispatch = useDispatch()
  const { company } = useCompany()
  const { itineraryId }: any = useParams()

  const onSelectExcursion = async (serviceId: string, optionId: string) => {
    setIsSubmitting(true)
    const action = await dispatch(selectExcursionOrExtra(serviceId, optionId))
    setIsSubmitting(false)

    if (!action.payload || action.payload.status !== 200) {
      dispatch(
        createNotification({
          content: 'Excursie selectie mislukt. Neem contact met ons op om uw voorkeur door te geven.',
          status: 'danger',
        })
      )
    } else {
      dispatch(fetchQuote(itineraryId, company))
    }
  }

  const includedItems = items?.filter((item: SummaryOptionType) => item.included)
  const excludedItems = items?.filter((item: SummaryOptionType) => !item.included)
  return (
    <>
      {includedItems?.length > 0 && (
        <Box m={0} mb="2rem">
          {includedItems.map((option: SummaryOptionType, index: number) => (
            <ListCard key={index}>
              <Alignment>
                <Box m={0} mr="1rem" inline>
                  <SummaryIconWrapper>
                    <CheckIconRounded color="success" />
                  </SummaryIconWrapper>
                </Box>

                {getDescription(option, itinerary)}
              </Alignment>
            </ListCard>
          ))}
        </Box>
      )}

      {excludedItems?.length > 0 && (
        <Box m={0} mb="2rem">
          <Box m={0} mb="1rem">
            <Text color="primary">Niet inbegrepen</Text>
          </Box>
          {excludedItems.map((option: SummaryOptionType, index: number) => (
            <ListCard key={index}>
              <Alignment>
                <Box m={0} mr="1rem" inline>
                  <SummaryIconWrapper>
                    <RemoveIconRounded color="danger" />
                  </SummaryIconWrapper>
                </Box>

                {getDescription(option, itinerary)}
              </Alignment>

              {(type === 'excursion' || type === 'extra') && itinerary?.status === 'open' && (
                <LabelButton disabled={isSubmitting} onClick={() => onSelectExcursion(option.serviceId, option.id)}>
                  Toevoegen
                </LabelButton>
              )}
            </ListCard>
          ))}
        </Box>
      )}
    </>
  )
}

const Summary: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const { itineraryId }: any = useParams()
  const { summary, itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)
  const screenClass = useScreenClass()
  const inlineButtons = ['sm', 'md', 'lg', 'xl', 'xxl'].includes(screenClass)
  if (!itinerary || !summary) {
    return null
  }

  const days = moment(itinerary.endDate).diff(itinerary.startDate, 'days') + 1

  const additionalCosts = itinerary.totalCosts - itinerary.basePrice
  const additionalCostPerPerson = (itinerary.totalCosts - itinerary.basePrice) / (itinerary.travellers || 1)

  return (
    <Container>
      <Box m={0} mt="4rem">
        <Card>
          <Row>
            <Col sm={3}>
              <strong>Naam:</strong>
            </Col>
            <Col sm={9}>
              <span>{itinerary.name}</span>
            </Col>
          </Row>
          {!itinerary.hideDates && (
            <>
              <Row>
                <Col sm={3}>
                  <strong>Vertrek:</strong>
                </Col>
                <Col sm={9}>
                  <span>
                    <Moment format="D MMMM YYYY">{itinerary.startDate}</Moment>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <strong>Terugkomst:</strong>
                </Col>
                <Col sm={9}>
                  <span>
                    <Moment format="D MMMM YYYY">{itinerary.endDate}</Moment>
                  </span>
                </Col>
              </Row>
            </>
          )}

          <Row>
            <Col sm={3}>
              <strong>Reisduur:</strong>
            </Col>
            <Col sm={9}>
              <span>
                {days} dag{days !== 1 ? 'en' : ''}
              </span>
            </Col>
          </Row>
          <Box m={0} mt="1rem">
            <Row>
              <Col sm={3}>
                <strong>Aantal personen:</strong>
              </Col>
              <Col sm={9}>
                <span>{itinerary.travellers}</span>
              </Col>
            </Row>
            {!!itinerary.hidePrices && !!itinerary.showUpsell && !!additionalCosts && (
              <>
                <Row>
                  <Col sm={3}>
                    <strong>Meerprijs per persoon:</strong>
                  </Col>
                  <Col sm={9}>
                    <span>{currency.format(additionalCostPerPerson)}</span>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <strong>Totale meerprijs:</strong>
                  </Col>
                  <Col sm={9}>
                    <strong>
                      <Text color="neutral">{currency.format(additionalCosts)}</Text>
                    </strong>
                  </Col>
                </Row>
              </>
            )}
            {!itinerary.hidePrices && (
              <>
                <Row>
                  <Col sm={3}>
                    <strong>Prijs per persoon:</strong>
                  </Col>
                  <Col sm={9}>
                    <span>{currency.format(itinerary.totalCosts / (itinerary.travellers || 1))}</span>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <strong>Totaalkosten:</strong>
                  </Col>
                  <Col sm={9}>
                    <strong>
                      <Text color="neutral">{currency.format(itinerary.totalCosts)}</Text>
                    </strong>
                  </Col>
                </Row>
              </>
            )}
          </Box>
        </Card>
      </Box>

      {summary.flights.length > 0 && (
        <>
          <Box m={0} inline>
            <H3>Vluchten</H3>
          </Box>
          <SummaryItems items={summary.flights} isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting} />
        </>
      )}
      {summary.accommodations.length > 0 && (
        <>
          <Box m={0} inline>
            <H3>Accommodaties</H3>
          </Box>
          <SummaryItems
            items={summary.accommodations}
            itinerary={itinerary}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
          />
        </>
      )}
      {summary.excursions.length > 0 && (
        <>
          <Box m={0} inline>
            <H3>Excursies</H3>
          </Box>
          <SummaryItems
            type="excursion"
            items={summary.excursions}
            itinerary={itinerary}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
          />
        </>
      )}
      {summary.extras.length > 0 && (
        <>
          <Box m={0} inline>
            <H3>Extra&lsquo;s</H3>
          </Box>
          <SummaryItems
            type="extra"
            items={summary.extras}
            itinerary={itinerary}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
          />
        </>
      )}
      {summary.rentals.length > 0 && (
        <>
          <Box m={0} inline>
            <H3>Vervoer</H3>
          </Box>
          <SummaryItems
            items={summary.rentals}
            itinerary={itinerary}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
          />
        </>
      )}
      {summary.miscellaneous.length > 0 && (
        <>
          <H3>Services</H3>
          <SummaryItems
            items={summary.miscellaneous}
            itinerary={itinerary}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
          />
        </>
      )}

      <Box m={0} mt="1rem" mb="8rem" inline={inlineButtons}>
        <Box m={0} mb="1rem" mr="1rem">
          <Link to={`/${itineraryId}/extras`}>
            <Button type="neutral" variant="dark" small beforeIcon={<ArrowLeftIcon size="1rem" />}>
              Extra&lsquo;s
            </Button>
          </Link>
        </Box>

        {!itinerary.hidePrices && !itinerary.hideDates && (
          <Link to={itinerary?.status === 'booked' ? `/${itineraryId}/travellers` : `/${itineraryId}/book`}>
            <Button type="primary" small afterIcon={<ArrowRightIcon size="1rem" />}>
              {itinerary?.status === 'booked' ? 'Reizigers' : 'Reis boeken'}
            </Button>
          </Link>
        )}
      </Box>
    </Container>
  )
}

export default Summary
