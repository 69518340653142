import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-grid-system'

import { H2 } from 'styles/Heading'
import Button from 'styles/Button'

import { ArrowRightIcon } from 'styles/Icon'

import { GuideType } from 'types'

type TravelGuideProps = {
  guide: GuideType
}

const GuideWrapper = styled.div`
  max-width: 80%;
  width: 800px;
  margin: 0 auto;
  margin-top: 15rem;
  margin-bottom: 15rem;

  @media (max-width: 991px) {
    display: block;
    text-align: center;
  }
`

const ProfileImage = styled.img`
  max-width: 14rem;
  max-height: 14rem;
  width: 100%;
  border-radius: 50%;
  margin-bottom: 2rem;
`
const ZRAImage = styled.img`
  max-width: 14rem;
  max-height: 14rem;
  width: 100%;
  height: 100%;
  object-fit: none;
  object-position: center;
`

const TravelGuide: React.FC<TravelGuideProps> = ({ guide }: TravelGuideProps) => (
  <GuideWrapper>
    <Row gutterWidth={50}>
      <Col md={12} lg={4}>
        {guide.isZRA ? <ZRAImage src={guide.image} /> : <ProfileImage src={guide.image} />}
      </Col>
      <Col md={12} lg={8}>
        <strong>Uw reisadviseur</strong>
        <H2>{guide.name}</H2>
        <p>{guide.description}</p>
        <a href={`mailto:${guide.email}`}>
          <Button type="primary" afterIcon={<ArrowRightIcon color="primary" size="1rem" />} outline>
            Stel mij een vraag
          </Button>
        </a>
      </Col>
    </Row>
  </GuideWrapper>
)

export default TravelGuide
