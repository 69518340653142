import React, { useMemo } from 'react'

import Box from 'styles/Box'

import { H3 } from 'styles/Heading'
import Alert from 'styles/Alert'

import { ExcursionOptionType, FlightType, QuoteDataType } from 'types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'reducers'
import CheckoutLuggageOption from 'components/Checkout/CheckoutLuggageOption/CheckoutLuggageOption'
import CheckoutOption from '../../components/Checkout/CheckoutOption/CheckoutOption'

const ExtrasContainer = styled.div`
  h3 {
    font-family: 'Oswald', 'Raleway', sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    color: #000;
    font-weight: normal;
  }
`

const CheckoutExtras: React.FC = () => {
  const { highlightedExcursions, highlightedExtras }: QuoteDataType = useSelector(
    (state: ApplicationState) => state.quote.data
  )

  const sortedExcursions = useMemo(() => {
    return highlightedExcursions?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
  }, [highlightedExcursions])

  const { flights }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)
  const selectedFlight = flights?.find((flight: FlightType) => flight.options.find((option) => option.selected))
  const selectedFlightOption = selectedFlight?.options.find((option) => option.selected)
  return (
    <ExtrasContainer>
      {selectedFlight && selectedFlightOption && !selectedFlightOption?.holdLuggage && (
        <>
          <H3>Ruimbagage</H3>
          <CheckoutLuggageOption flightId={selectedFlight.id} option={selectedFlightOption} />
        </>
      )}

      {sortedExcursions?.length > 0 && <H3>Excursies</H3>}
      {sortedExcursions?.map((option: ExcursionOptionType, index: number) => {
        return <CheckoutOption type="excursie" key={index} option={option} />
      })}

      {highlightedExtras?.length > 0 && <H3>Extra&rsquo;s</H3>}
      {highlightedExtras?.map((option: ExcursionOptionType, index: number) => {
        return <CheckoutOption type="extra" key={index} option={option} />
      })}
    </ExtrasContainer>
  )
}

export default CheckoutExtras
