import React, { ReactElement } from 'react'
import {
  FlightIcon,
  HotelIcon,
  WineglassIcon,
  CarIcon,
  ChecklistIcon,
  CreditcardIcon,
  InvoiceIcon,
  SuitcaseIcon,
  TravellersIcon,
  CompassIcon,
  AddIcon,
} from 'styles/Icon'

import { IconType } from 'styles/Icon/Icon'

export type MenuItem = {
  link: string
  name: string
  icon: ReactElement<IconType>
  display: boolean
}

const getMenuItems = (
  isBooked: boolean,
  isZRA: boolean,
  adjustTravellers: boolean,
  hidePrices?: boolean,
  hideDates?: boolean,
  isExcursion?: boolean
): MenuItem[] => {
  if (isExcursion) {
    return []
  }

  const items = [
    {
      link: 'itinerary',
      name: 'Programma',
      icon: <SuitcaseIcon size="1rem" />,
      display: true,
    },
    {
      link: 'flights',
      name: 'Vluchten',
      icon: <FlightIcon size="1rem" />,
      display: true,
    },
    {
      link: 'accommodations',
      name: 'Accommodaties',
      icon: <HotelIcon size="1rem" />,
      display: true,
    },
    {
      link: 'excursions',
      name: 'Excursies',
      icon: <CompassIcon size="1rem" />,
      display: true,
    },
    {
      link: 'rentals',
      name: 'Vervoer',
      icon: <CarIcon size="1rem" />,
      display: true,
    },
    {
      link: 'extras',
      name: "Extra's",
      icon: <AddIcon size="1rem" />,
      display: true,
    },
    {
      link: 'summary',
      name: 'Samenvatting',
      icon: <ChecklistIcon size="1rem" />,
      display: true,
    },
    {
      link: 'travellers',
      name: 'Reizigers',
      icon: <TravellersIcon size="1rem" />,
      display: (isZRA && adjustTravellers) || isBooked,
    },
  ]
  if (!hidePrices && !hideDates)
    items.push({
      link: 'book',
      name: isBooked ? 'Factuur' : 'Boeken',
      icon: isBooked ? <InvoiceIcon size="1rem" /> : <CreditcardIcon size="1rem" />,
      display: (!isZRA && isBooked) || !isBooked,
    })
  return items
}

export default getMenuItems
