import styled from 'styled-components'

const TH = styled.th`
  padding: 0 0.5rem;
  letter-spacing: 1.25px;
  font-size: 0.85rem;
  font-weight: normal;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.neutral.dark};
`

export default TH
